// en
export const terms_and_conditions = {
  title: "Terms and Conditions",
  subtitle: "General Terms and Conditions",
  data_privacy: "Data Protection",
  intro_container: {
    title: "Key Points",
    first_paragraph:
      "By registering, a contract for the provision of digital services is concluded. This does not incur {no_cost} for the user.",
    no_cost: "no costs",
    second_paragraph:
      "This is possible by placing legal job advertisements (e.g., from law firms), event notices (e.g., from tutors), and advertisements (e.g., for textbooks and similar) on our website. If we personalize this advertising, we do not pass {no_data_to_advertisers} to advertisers but perform the personalization ourselves on our own European servers. For example, we show users who open § 433 BGB textbooks on sales law without our advertising partners knowing who opens § 433 BGB.",
    no_data_to_advertisers: "no data to advertisers",
    third_paragraph:
      "Premium subscriptions can be concluded for the use of additional functions published after market launch. However, the functions offered at market launch will also {stay_free}.",
    stay_free: "remain free of charge in the future",
  },
  content: {
    section_intro: [
      {
        title: "General Terms and Conditions",
        paragraphs: [
          "LexMea GmbH, Leipziger Str. 44, 10117 Berlin (hereinafter referred to as 'LexMea' or 'we') operates a web application under the name 'LexMea' at lexmea.de.",
          "These General Terms and Conditions in their current version at the time of the conclusion of the contract apply to business relationships between LexMea and the user (hereinafter referred to as 'user').",
        ],
      },
    ],
    section_1: [
      {
        title: "§ 1 Conclusion of Contract",
        paragraphs: [
          "A usage contract between the user and LexMea is concluded by confirming the registration of a user account. The provision of the database and its contents by LexMea does not constitute a binding offer. It merely gives the customer the opportunity to make a binding offer to conclude a usage contract.",
          "The offer is available to the user after confirming their registration data via a verification link. After the period specified in the verification request for activating the user account has expired, the user's data will be deleted, and a new registration will be required.",
        ],
      },
    ],
    section_2: [
      {
        title: "§ 2 Scope of Use and Changes",
        paragraphs: [
          "LexMea provides the user with numerous functions (hereinafter referred to as 'digital services') free of charge as part of the usage contract:",
        ],
        list: [
          "Access to all standard functions of the database,",
          "Use of all standard personalization functions (underlining, highlighting, references, own schemes, and notes)",
          "Access to all standard LexMea schemes",
          "Access to information offers and advertisements (job offers, event notices, textbooks, etc.).",
        ],
        paragraphs_after_list: [
          "LexMea continuously adapts its offer to current requirements. For this purpose, editorial content is periodically updated, and technical changes are made to maintain user-friendliness and usability of LexMea and to adapt to the current state of the art (agreed updates).",
          "Further changes to these digital services (upgrades) are made only for a good reason, such as adapting the product to user feedback, a new technical environment, or increased user numbers, as well as other important operational reasons, and informing the users without incurring additional costs for such changes.",
          "The usage contract for the use of these digital services is concluded for an indefinite period and can be terminated at any time without notice by both parties. If a paid service extension has been concluded, it must be terminated first according to § 11. The right to terminate without notice for good cause remains unaffected. Good cause for LexMea exists in particular if the user has seriously or repeatedly violated these General Terms and Conditions or other contractual obligations despite a warning from LexMea (especially if there is a serious or repeated case of misuse against the cooperation and security obligations).",
          "The user terminates the digital service by deleting the user account in the user account settings.",
          "Upon termination, there is no longer any access to the account and the data stored there. Exceptions are data that LexMea is legally obliged to retain or has a legitimate interest in retaining, e.g., to prevent re-registration after a justified suspension.",
        ],
      },
    ],
    section_3: [
      {
        title: "§ 3 Service Extensions",
        paragraphs: [
          "LexMea continuously expands its range of services and introduces new functions (service extensions). LexMea therefore reserves the right to provide, test, and remove service extensions that are not contractually owed without informing users separately and individually, as long as this does not affect the rights or obligations of the users and does not concern the digital services listed under § 2. Unlike the digital services listed under § 2, service extensions may only be available with a separate subscription.",
        ],
      },
    ],
    section_4: [
      {
        title: "§ 4 Availability",
        paragraphs: [
          "LexMea strives to provide its entire offer as uninterrupted as possible and at least 95% of the time over the calendar year. Necessary and regular maintenance work, which is announced in advance, is excluded.",
          "Non-availability due to internet disruptions, force majeure, or other circumstances such as war or pandemics/epidemics, which LexMea is not responsible for, is excluded.",
          "Non-availability due to cyber-attacks that could not have been prevented even with reasonable care in cybersecurity is excluded.",
        ],
      },
    ],
    section_5: [
      {
        title: "§ 5 Copyright and Usage Rights",
        paragraphs: [
          "The user receives a simple, personal, royalty-free, non-exclusive, non-transferable, revocable, and non-sublicensable license to access and use the digital services. This license and any access authorization to the service are automatically revoked if the user violates their cooperation obligations under § 6.",
          "This license entitles the user to use the digital services on LexMea. Editorially created content (especially examination schemes) may be retrieved, downloaded, locally stored, copied, and modified.",
          "All rights not expressly granted to the user in this contract are reserved by LexMea. The user acknowledges that the database is a database work created by LexMea or a database within the meaning of §§ 4 para. 2, 87a para. 1 UrhG. Associated computer programs are subject to the protection of §§ 69a ff. UrhG. Provided content is subject to the protection of § 2 UrhG. Third-party rights to protected works and content remain unaffected.",
          "For the function of sharing own content with other users, the user grants LexMea a license to host, use, distribute, modify, and display this content according to the user settings. The release of own content must be activated separately and can be managed individually in the account. The user warrants to LexMea that the content can be used free of third-party rights. LexMea reserves the right to block or delete published content in case of legal violations.",
        ],
      },
    ],
    section_6: [
      {
        title: "§ 6 Cooperation and Security Obligations",
        paragraphs: [
          "The user must create the technical prerequisites for using the web app and retrieving the content. This is particularly the use of a common browser with up-to-date software.",
          "The user undertakes to take common security precautions to protect their systems from malware. The user takes appropriate protective measures to prevent data espionage (§ 202a StGB), data alteration (§ 303a StGB), or data sabotage (§ 303b StGB).",
          "The user must keep the login data used to register with LexMea confidential. They are solely responsible for all activities that occur under these login data. If there is a suspicion that someone has gained access to the user account, the user must inform LexMea immediately by email at {email}.",
          "The user refrains from the following within the scope of their cooperation obligations:",
        ],
        list: [
          "using LexMea's offer in a way that could impair, disrupt, or negatively affect the offer, the servers, or the networks connected to the offer.",
          "uploading viruses or other malicious code or otherwise endangering the security of the offer.",
          "manipulating identification features to obscure the origin of information transmitted through the service.",
          "framing parts of the offer without LexMea's prior written consent (i.e., including a part of the page as a frame in another page) or mirroring (i.e., saving with all subpages).",
          "using meta-tags (encoded information stored in the head area of an HTML document and used to specify metadata), codes, or other devices that contain a reference to LexMea or the offer, a brand, a trade name, a service mark, a logo, or slogan of LexMea to direct people to other websites (e.g., using Google-Keywords Ads / SEA).",
          "modifying, adapting, sublicensing, translating, selling, reverse engineering, decrypting, decompiling, or disassembling parts of our offer or causing others to do so.",
          "facilitating or promoting activities that violate this contract.",
        ],
      },
    ],
    section_7: [
      {
        title: "§ 7 Withdrawal",
        paragraphs: [
          "Consumers have a right of withdrawal. Consumers are natural persons who conclude a legal transaction for purposes that are predominantly neither commercial nor self-employed.",
        ],
        subsections: [
          {
            title: "Withdrawal Policy",
            paragraphs: [
              "Consumers have the right to withdraw from this contract within fourteen days without giving any reason. The withdrawal period is fourteen days from the day of the conclusion of the contract. To meet the withdrawal deadline, it is sufficient for the user to send the communication regarding the exercise of the right of withdrawal before the withdrawal period has expired.",
              "The right of withdrawal expires prematurely if LexMea has fully provided the owed service and has only begun to perform the service after the user has given their express consent and simultaneously confirmed their knowledge that they lose their right of withdrawal upon full performance of the contract by LexMea.",
              "To exercise the right of withdrawal, the user must inform LexMea of their decision to withdraw from this contract by means of a clear statement (e.g., a letter sent by post or email to support{'@'}lexmea.de). The user may use the sample withdrawal form below but is not obliged to do so.",
            ],
          },
          {
            title: "Consequences of Withdrawal",
            paragraphs: [
              "If the user withdraws from this contract, LexMea must reimburse the user for all payments received from the user, including delivery costs (except for the additional costs arising from the fact that the user has chosen a type of delivery other than the cheapest standard delivery offered by LexMea), without undue delay and at the latest within fourteen days from the day on which the communication of the withdrawal of this contract was received by LexMea. For this repayment, LexMea will use the same means of payment that the user used for the original transaction unless expressly agreed otherwise; in no case will the user be charged fees for this repayment.",
              "If the user has requested that a paid service extension should begin during the withdrawal period, the user must pay LexMea a reasonable amount corresponding to the proportion of the services already provided up to the time the user informs LexMea of the exercise of the right of withdrawal concerning this contract compared to the total scope of the services provided for in the contract.",
            ],
          },
        ],
      },
    ],
    section_7_B: [
      {
        title: "Sample Withdrawal Form",
        paragraphs: [
          "For the withdrawal, this withdrawal form can be filled out and sent to support{'@'}lexmea.de or LexMea GmbH, Leipziger Str. 44, 10117 Berlin (delete as applicable):",
        ],
        list: [
          "I hereby withdraw from the contract I concluded for the provision of the following service: ____________________",
          "Ordered on: __________________",
          "Name of the user: ___________________",
          "Address of the user or email address: ________________",
          "User's signature (only if notified on paper)",
          "Date",
        ],
      },
    ],
    section_8: [
      {
        title: "§ 8 Warranty and Liability",
        paragraphs: [
          "LexMea strives to keep the content of the database up to date and to exercise due care in creating content, relying on the supply of data from third parties. The accuracy, timeliness, and completeness of the provided content, laws, and other compilations cannot be guaranteed. The user is advised to conduct a plausibility check of the content according to its use.",
          "LexMea is liable according to the statutory provisions without limitation for damages resulting from injury to life, body, or health, in the case of fraudulent concealment of defects, for claims under the Product Liability Act, and for damages based on intent or gross negligence by LexMea or a legal representative or vicarious agent, as well as for damages due to non-compliance with a guarantee given by LexMea, an assured characteristic, or a procurement risk assumed by LexMea.",
          "In other cases, LexMea is liable, limited to compensation for the foreseeable damage typical for the contract, for such damages based on a slightly negligent breach of essential contractual obligations by LexMea or a legal representative or vicarious agent. Essential contractual obligations are obligations whose fulfillment makes the proper execution of the contract possible in the first place.",
          "Further liability is excluded.",
          "The strict liability of LexMea for defects already existing at the time of the conclusion of the contract under § 536a para. 1 sentence 1 BGB is excluded.",
        ],
      },
    ],
    section_9: [
      {
        title: "§ 9 Data Protection",
        paragraphs: [
          "Data protection is of the highest priority for LexMea. We therefore go beyond the already high level of protection of the European General Data Protection Regulation in many areas. Our data protection notices explain how we collect and use personal data.",
          "The use of the digital services provided at market launch by LexMea is free of charge and will remain so in the future. We can ensure this through advertisements. If we personalize this advertising, we do not pass any data to advertising partners but perform the personalization ourselves on our own European servers. For example, we show users who open § 433 BGB textbooks on sales law without our advertising partners knowing who opens § 433 BGB. LexMea collects, uses, and processes personal data to offer the user personalized content and advertisements. In the privacy cockpit, the user can check and centrally manage their privacy settings regarding the use of their data at any time.",
          "LexMea ensures that all persons involved in processing personal data have signed a commitment to data secrecy.",
          "In the event that LexMea acts as a processor under Art. 28 General Data Protection Regulation for the customer, the customer grants LexMea the general authorization to engage further processors (especially the European server provider Hetzner Online based in Gunzenhausen). LexMea itself implements appropriate technical and organizational measures to ensure that the processing is carried out in accordance with the requirements of the General Data Protection Regulation and ensures the protection of the rights of the data subject.",
          "The user can optionally consent to receive a newsletter (product changes, advertisements, events, etc.) during the registration process. Since spam emails are annoying, we only send an email for really relevant news – for example, when introducing entirely new functions on LexMea, special events in the user's region, or their legal field. This can be revoked by the user at any time in the profile settings or via a link at the end of each email.",
        ],
      },
    ],
    section_10: [
      {
        title: "§ 10 General Provisions",
        paragraphs: [
          "Conflicting, deviating, or additional General Terms and Conditions of the user do not become part of the contract unless LexMea expressly agrees to their use. These General Terms and Conditions also apply in the event of service provision by LexMea with knowledge of conflicting or deviating General Terms and Conditions of the user.",
          "There are no oral side agreements or supplements at the time of the conclusion of the contract. Side agreements, contract changes, or supplements require text form to be effective. The requirement for text form can only be waived in text form.",
          "As an online company, we are obliged under VSBG and VO (EU) 524/2013 to inform consumers within the meaning of § 13 BGB about the platform for online dispute resolution of the European Commission. This is available at: https://webgate.ec.europa.eu/odr. We are generally willing but not obliged to participate in such a dispute resolution procedure.",
          "The place of performance is Berlin. The exclusive place of jurisdiction for disputes between LexMea and users who are merchants, legal entities, or special funds under public law is Berlin.",
          "The law of the Federal Republic of Germany applies to all disputes arising from the usage relationship with LexMea. The application of legal norms that refer to another legal system is excluded.",
          "We reserve the right to change these General Terms and Conditions for good reasons – in particular due to a changed legal situation, supreme court rulings, if the contract changes are only favorable to the user, or for similar reasons, if the changes do not affect the mutual main performance obligations and do not unreasonably disadvantage the user contrary to good faith – with a notice period of two months in advance.",
          "Future changes to these General Terms and Conditions, which are necessary due to technical changes or developments, new organizational requirements of mass traffic, gaps in the General Terms and Conditions, changes in market conditions, or other equivalent reasons and do not unreasonably disadvantage the customer and do not lead to an increased payment obligation on the part of the user, will be offered to the customer at least two months before the proposed date of their effectiveness by email or in text form. The changes will be presented in a highlighted form, for example, through a synoptic comparison or by highlighting the changes in bold. If the user has agreed with LexMea to communicate electronically within the business relationship (e.g., push notifications or LexMea mailbox), the changes can also be offered in this way. The changes become effective if the user does not indicate their rejection before the proposed date of the changes' effectiveness by email or in writing and LexMea has pointed out this legal consequence in the change notification. The user can also terminate the contract without notice before the proposed date of the changes' effectiveness. LexMea will also particularly point this out to the user.",
        ],
      },
    ],
    section_11: [
      {
        title: "§ 11 Special Provisions for Paid Service Extensions",
        subsections: [
          {
            title: "Prices and Scope of Services",
            paragraphs: [
              "The terms of the offer (prices, conditions, scope of services) and separate terms of use for the paid service extensions are derived from the version of the price information and product descriptions on the LexMea (lexmea.de) websites valid at the time of the order.",
              "The usage rights are limited to the product ordered by the user. They are personal, non-exclusive, non-transferable, revocable, and non-sublicensable. Simultaneous access by multiple users using the same personal access data is not possible. This license and any access authorization to the service will be automatically revoked if the user violates their cooperation and security obligations under these General Terms and Conditions. All rights not expressly granted to the user in this contract are reserved by LexMea.",
              "Unless otherwise specified in the offer conditions of the respective product available on lexmea.de or otherwise agreed in writing with LexMea, the remuneration is to be paid in advance without deduction. Any costs for accessing individual services are clearly marked and are only due upon separate activation.",
              "If the user selects an external payment provider when concluding a paid service extension, LexMea uses this provider without this provider becoming a contracting party.",
              "If LexMea offers and accepts direct debit via SEPA direct debit as a payment method, the announcement of the direct debit (“Pre-Notification”) is made with a notice period of at least one working day. If the collection of the claim fails despite timely notification and the user is responsible for the failure, they must reimburse LexMea for the additional costs incurred.",
              "In the event of default by the customer, LexMea is entitled to block access to online services and to stop the delivery of further products ordered by the customer until all due claims from the entire business relationship with the customer, regardless of the legal basis, have been settled by the customer. Monetary debts are to be paid by the customer during the default according to the statutory provisions, particularly according to § 288 BGB.",
              "LexMea is entitled to increase prices for long-term obligations, provided that these do not exceed 7% within one year since the last price increase or 15% within three consecutive years since the last price increase of the previously applicable price and the price increase corresponds to the cost increase incurred by LexMea for the production and provision of the products, particularly the necessary costs for the maintenance, upkeep, and further development of the technical and personnel infrastructure used for the service provision or the necessary costs for the licensing of third-party works, or if the price increase corresponds to the increase in market prices of the respective print works provided in the online services. Price increases become effective at the beginning of the third month after receipt of the change notification by the customer unless a longer period is specified in the change notification. The customer is entitled to terminate the contract extraordinarily within six weeks from the notification of the price increase at the time the price increase takes effect. If the customer does not exercise this right and has been informed of this legal consequence in the price increase notification, the contract will continue at the changed prices.",
              "If the scope of services of online services is expanded more than insignificantly, LexMea is entitled to increase the prices appropriately, namely in proportion to the expansion that has occurred. The increase becomes effective at the beginning of the second month after receipt of the change notification by the customer. The customer is entitled to terminate the contract extraordinarily within six weeks from the notification of the price increase at the time the price increase takes effect. If the customer does not exercise this right and has been informed of this legal consequence in the price increase notification, the contract will continue at the changed prices.",
            ],
          },
          {
            title: "Term and Termination",
            paragraphs: [
              "Unless otherwise specified in the offer conditions on LexMea.de, paid service extensions are concluded at the user's choice with a term of one month or one year. If the user does not terminate the paid service extension at least five days before the end of the term, the contractual relationship is extended indefinitely with a notice period of one month. LexMea can terminate extended contractual relationships with a notice period of three months if tariff options expire. Users can easily terminate their contracts by email to support{'@'}lexmea.de or via the termination button in the user account settings. In the event of ordinary termination of the contract, LexMea will terminate the user's access to the paid service extensions at the end of the term. The use of free services remains possible using the same account. The right to terminate the contract for good cause remains unaffected. A good cause for LexMea exists, in particular, if the customer is in arrears with the payment of an invoice despite at least two reminders or if at least preliminary insolvency proceedings have been opened over the customer's assets. Claims for damages remain unaffected.",
            ],
          },
        ],
      },
    ],
  },
};
