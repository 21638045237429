const COOKIE_MODAL_DISABLED = "cookieModalDisabled";

export function useCookieModal() {
  const rawCookie = useCookie<boolean>(COOKIE_MODAL_DISABLED)();
  const isShowcase = useExamShowcase();

  return computed({
    get: () => isShowcase || rawCookie.value,
    set: (val: boolean) => (rawCookie.value = val),
  });
}
