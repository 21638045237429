import type { IBookmark } from "~/models/ILegalTextParagraph";
import type { IUserEntry } from "~/models/IUserEntry";

export function useParagraphLink(props: {
  legalTextSlug?: MaybeRefOrGetter<string | undefined>;
  paragraphSlug?: MaybeRefOrGetter<string | undefined>;
  oldVersionId?: MaybeRefOrGetter<number | undefined>;
  entry?: MaybeRefOrGetter<IUserEntry | IBookmark | undefined>;
}) {
  const { active } = useFetchActiveEntry();

  const canonicalFromSchema = computed(() => {
    const entryVal = toValue(props.entry);
    if (entryVal && entryVal.type === "schema") {
      const split = entryVal.canonical_path.split("/");
      return [split[2], split[3]] as const;
    }
    return undefined;
  });

  const legalTextSlug = computed(() => {
    const val = toValue(props.legalTextSlug) ?? canonicalFromSchema.value?.[0];
    if (val) {
      return val;
    }

    const entry = toValue(props.entry);
    if (entry && "legal_text_paragraph" in entry) {
      return entry.legal_text_paragraph.legal_text?.slug;
    }

    return undefined;
  });

  const paragraphSlug = computed(() => {
    const val = toValue(props.paragraphSlug) ?? canonicalFromSchema.value?.[1];
    if (val) {
      return val;
    }

    const entry = toValue(props.entry);
    if (entry && "legal_text_paragraph" in entry) {
      return entry.legal_text_paragraph.slug;
    }

    return undefined;
  });

  const additionalEntry = computed(() => {
    const entryVal = toValue(props.entry);
    if (entryVal && (entryVal.type === "schema" || entryVal.type === "note")) {
      return entryVal;
    } else if (active.value && active.value.type === "schema") {
      return active.value;
    }
    return undefined;
  });

  const basePathWithoutSlug = computed(() => {
    const parts = ["gesetz", legalTextSlug.value, paragraphSlug.value || "toc"];

    return "/" + parts.join("/");
  });

  const entrySlug = computed(() => {
    const entryVal = toValue(additionalEntry);
    if (
      entryVal &&
      entryVal.curated &&
      "slug" in entryVal &&
      entryVal.slug &&
      entryVal.canonical_path === basePathWithoutSlug.value
    ) {
      return entryVal.slug;
    }
    return undefined;
  });

  const path = computed(() => {
    if (entrySlug.value) {
      return basePathWithoutSlug.value + "/" + entrySlug.value;
    }
    return basePathWithoutSlug.value;
  });

  const searchParams = computed(() => {
    const search = new URLSearchParams(
      !import.meta.env.SSR ? window.location.search : ""
    );
    search.delete("oldParagraph");
    search.delete("id");
    search.delete("type");

    const oldVersionId = toValue(props.oldVersionId);
    if (oldVersionId) {
      search.set("oldParagraph", String(oldVersionId));
    }

    if (entrySlug.value || !additionalEntry.value) {
      return search;
    }
    const { id, type } = additionalEntry.value;

    search.set("id", id.toString());
    search.set("type", type);
    return search;
  });

  const href = computed(() => {
    const query = searchParams.value.toString();
    return path.value + (query ? `?${query}` : "");
  });

  return { href, path, searchParams };
}
