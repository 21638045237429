export const bookshelf = {
  login: "log in",
  close: "Close",
  register: "register",
  login_or_register: "{login} or {register}",
  to_see_your_own_bookmarks: "to see your own bookmarks",
  toast: {
    law_book_already_in_quick_access: "The law book is already in quick access",
  },
  tooltips: {
    no_color: "No color",
    remove_from_quick_access: "Remove from quick access",
    title: "The library is your central overview page.",
    quick_access: "Place the law books you use most frequently here.",
    bookmarks: "Your bookmarked norms from the workspace are collected here.",
    law_books:
      "All laws currently available on LexMea are listed here. We are constantly expanding.",
    content_by_legal_field:
      "We have assigned the norms on LexMea to legal fields. Here, the content created by you (and us) is automatically displayed sorted by legal fields.",
  },
  tabs: {
    laws: "Laws",
    content: "Content",
    chronic: "Chronic",
  },
  laws: {
    filter_by_title: "Filter by title",
    keywords: {
      laws: "Laws",
      laws_az: "Laws (A-Z)",
      quick_access: "Quick Access",
      bookmark: "Bookmark",
      bookmarks: "Bookmarks",
      law_books: "Statutes and Legal Texts",
      contents_by_old_norms: "Contents by old norms",
      content_by_legal_field: "Contents by field of law",
    },
    lawTypes: {
      alle: "All",
      de: "Federal Law (DE)",
      state: "State Law (DE)",
      eu: "European Law",
      int: "International Law",
    },
    info_logged_in: {
      no_bookmarks_yet: "You haven't created any bookmarks yet.",
      no_contents_to_field_of_law:
        "You haven't created any content for this legal field yet.",
      please_select_other_filters:
        "Please select other filter settings to display content here.",
      explanation:
        "You can store frequently used law books in your {quick_access} and personalize them with colors.",
      bookmarks_info:
        "Below, all norms marked with a {bookmark} in the workspace are collected.",
      third_overview:
        "The third overview shows you all {laws_az} available on LexMea.",
    },
    info: {
      log_in_to_view_sorted_contents:
        "Log in to see a sorted overview of the created content by fields of law.",
      more_info:
        "Further down you will already find an overview of the statute books available on LexMea {laws_az}.",
      explanation:
        "To store frequently used statute books in your {quick_access} in your chosen colour and to see an overview of the provisions marked with a {bookmark}, {action}.",
    },
  },
  content: {
    keywords: {
      by_legal_field: "by legal fields",
      filter_icon: "Filter Icon",
      lexmea_schemata: "LexMea @:documents",
      own_schemata: "Custom @:documents",
      notes: "Notes",
      references: "References",
      markings: "Markings",
      underlines: "Underlines",
      bookmarks: "Bookmarks",
    },
    info: {
      intro:
        "We have assigned all provisions on LexMea to fields of law. {contents} created in the Workspace (eg. documents, notes, and references) is therefore displayed here, sorted {by_legal_field}.",
      logged_in:
        "Use the {filter_icon} to set which type of content should be displayed to you.",
      no_user: {
        part_2: "You already see {all_documents}.",
        all_documents:
          "all @.lower:documents created by LexMea's editorial staff",
        part_3:
          "To see your currently only locally saved entries displayed here,",
      },
    },
  },
  chronic: {
    filter: {
      own: "Own content",
      others: "Others' content",
    },
    table: {
      type: "Type",
      norm: "Norm",
      name_or_section: "Name / Section",
      field_of_law: "Field of Law",
      recover: "Recover",
      deleted_at: "Deleted at",
      updated_at: "Created / Edited at",
    },
    restore: {
      element_restored: "Element restored",
      abort: "Abort",
      change_to_norm: "Switch to norm",
      successfully_restored:
        "{restoredType} successfully restored. Do you want to switch to {lastRestoredEntry}?",
    },
    errors: {
      marking_recover_failed: "Marking could not be restored.",
    },
    info: {
      logged_in:
        "Content created in the workspace according to standards ( @.lower:documents @:comma markings, etc.) is displayed here sorted by edit date. Deleted content can be restored from the trash within 30 days.",
      no_user:
        "{action} to get your content created in the workspace according to standards (@.lower:documents@:comma markings, etc.) displayed here sorted by edit date and to be able to restore deleted content from the trash within 30 days.",
    },
    current: {
      heading: "Content by creation date",
      subtitle:
        "Here you will find a chronologically sorted list of the content you have created.",
      empty_all: "You haven't created anything yet.",
      empty_type: "You haven't created any {type} yet.",
      no_user:
        "{action} to view a chronicle of all the content you have created here.",
    },
    deleted: {
      heading: "Trash",
      subtitle:
        "Here you will find a chronologically sorted list of the content you have deleted.",
      empty_all: "You haven't deleted any content yet.",
      empty_type: "You haven't deleted any {type} yet.",
      no_user: "{action} to view your deleted content here.",
    },
  },
};
