<template>
  <div>
    <label
      class="flex cursor-pointer items-center"
      :class="[small ? 'text-xs' : 'text-base']"
    >
      <input
        v-model="model"
        type="checkbox"
        :indeterminate="indeterminate"
        class="lexmea-checkbox mr-2"
        v-bind="$attrs"
      />
      <slot class="overflow-hidden">
        {{ label }}
      </slot>
    </label>
    <span v-if="error && error.length > 0" class="text-sm text-maroon-flush">
      {{ typeof error === "string" ? error : error[0] }}
    </span>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script lang="ts" setup generic="T">
type Props = {
  error?: string | string[];
  label?: string;
  small?: boolean;
  indeterminate?: boolean;
};

// TODO: div is not valid within label. Restructure this!
const { label = "", error = "", indeterminate = false } = defineProps<Props>();

const model = defineModel<Array<T> | boolean | null>({
  default: true,
});
</script>
