<template>
  <LexmeaModal
    titleTag="div"
    :noPaddingY="true"
    :closingDisabled="closingDisabled"
  >
    <template #title>
      <div
        class="flex w-full"
        :class="[
          closingDisabled
            ? 'justify-center'
            : 'justify-around md:justify-between',
        ]"
      >
        <button
          v-if="!showcase"
          v-ripple="{ color: 'blue' }"
          class="w-full border-b-4 p-3 font-bold transition"
          :class="[
            tab === 'register' && !closingDisabled
              ? 'border-lexmea-blue-400'
              : 'border-transparent',
          ]"
          @click="tab = 'register'"
        >
          {{
            closingDisabled
              ? $t("authentication.completeRegistration")
              : $t("authentication.register")
          }}
        </button>
        <button
          v-if="!closingDisabled"
          v-ripple="{ color: 'blue' }"
          class="w-full border-b-4 p-3 font-bold transition"
          :class="[
            tab === 'login' ? 'border-lexmea-blue-400' : 'border-transparent',
          ]"
          @click="tab = 'login'"
        >
          {{ $t("authentication.login") }}
        </button>
      </div>
    </template>
    <template #default="{ close }">
      <div class="pt-5 md:px-8">
        <KeepAlive>
          <LoginTab
            v-if="tab === 'login'"
            @close="close"
            @switchTab="tab = 'register'"
            @emailResent="onEmailResent"
          />
          <RegisterTab
            v-else-if="!showcase"
            ref="register"
            @close="close"
            @switchTab="tab = 'login'"
            @update:closingDisabled="closingDisabled = $event"
          />
        </KeepAlive>
      </div>
    </template>
  </LexmeaModal>
</template>

<script lang="ts">
export type Tab = "login" | "register";
</script>

<script setup lang="ts">
import type RegisterTab from "./RegisterTab.vue";

const showcase = useExamShowcase();

const tab = defineModel<Tab>("tab");

const closingDisabled = ref(false);

const register = ref<InstanceType<typeof RegisterTab> | null>(null);
const onEmailResent = async (email: string) => {
  tab.value = "register";
  await nextTick();
  register.value?.emailResentFromLogin(email);
};

const { vRipple } = useVRipple();

onMounted(async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const isVerifying = urlParams.get("verify_email");
  const email = urlParams.get("email");
  if (isVerifying && email) {
    tab.value = "register";
    await nextTick();
    register.value?.emailResentFromLogin(email);
  }
});
</script>
